import React from "react"
import Layout from "../components/global/layout"
import SEO from "../components/global/seo"
import SingleProduct from "../components/single-product-page/singleProduct"

import lipInfusionsHydrationProduct from "../images/product-and-package/lip-infusions-hydration-bleed.svg"
import lipInfusionsHydrationImage from "../images/products/lip-infusions-hydration-large.png"

// related product images
import lipInfusionsNourishProduct from "../images/product-and-package/lip-infusions-nourish.jpg"
import lipInfusionsSoothingProduct from "../images/product-and-package/lip-infusions-soothing.jpg"
import tescoLogo from "../images/stockists/tesco.png"
import bootsLogo from "../images/stockists/boots.png"
import ocadoLogo from "../images/stockists/ocado.png"
import morrisonsLogo from "../images/stockists/morrisons.png"
import superdrugLogo from "../images/stockists/superdrug.png"
import amazonLogo from "../images/stockists/amazon.png"
import sainsburysLogo from "../images/stockists/sainsburys.png"
//import wilkoLogo from "../images/stockists/wilko.png"

import everydayIcon from "../images/icons/product-icon/everyday.svg"
import outdoorsIcon from "../images/icons/product-icon/outdoors.svg"
import coldIcon from "../images/icons/product-icon/winter.svg"
import nightIcon from "../images/icons/product-icon/overnight.svg"

import BSGoldAward from "../images/awards/BS-Award-Gold.png"
import BSSilverAward from "../images/awards/BS-Award-Silver.png"
import BSBronzeAward from "../images/awards/BS-Award-Bronze.png"

const product = {
  image: lipInfusionsHydrationProduct,
  productImage: lipInfusionsHydrationImage,
  productImageStyle: "bleed",
  title: (
    <h1 className="product-overview__heading">Lip Infusions - Hydration.</h1>
  ),
  alt: "Lip Infusions - Hydration.",
  subTitle: (
    <h2 className="product-overview__sub-heading">
      <br />
      Infused with Hyaluronic Spheres to lock in hydration
      <br />
    </h2>
  ),
  disclaimer: "",
  awards: [
    { title: "SILVER and BEAUTY STEAL in LIP BALM", img: BSSilverAward },
  ],
  icons: [
    { title: "everyday", icon: everydayIcon },
    { title: "outdoors", icon: outdoorsIcon },
    { title: "cold weather", icon: coldIcon },
    { title: "at night", icon: nightIcon },
  ],
  overviewCopy: (
    <div>
      <br />
      <p>
        Blistex Lip Infusions Hydration has a long-lasting lip care formula that
        provides perfectly balanced hydration to drench your lips in moisture
        without a heavy, greasy feel and is clinically shown to moisturise for
        up to 24 hours.
      </p>
      <ul>
        <li>Infused with Hyaluronic Spheres to lock in hydration</li>
        <li>Fortified with Vitamins E &amp; F to soften and smooth</li>
      </ul>
    </div>
  ),
  listID: "",
  stockists: [
    {
      stockistName: "Boots",
      stockistLink:
        "https://www.boots.com/blistex-lip-infusions-hydration-10301404",
      stockistImage: bootsLogo,
      classModifier: "",
      showLink: "true",
    },
    {
      stockistName: "Tesco",
      stockistImage: tescoLogo,
      stockistLink: "https://www.tesco.com/groceries/en-GB/products/308826894",
      classModifier: "",
      showLink: "true",
    },
    {
      stockistName: "Ocado",
      stockistImage: ocadoLogo,
      stockistLink:
        "https://www.ocado.com/products/blistex-lip-infusions-hydration-552888011",
      classModifier: "",
      showLink: "true",
    },
    {
      stockistName: "Morrisons",
      stockistImage: morrisonsLogo,
      stockistLink:
        "https://groceries.morrisons.com/products/blistex-lip-infusions-hydration-spf-15-559044011",
      classModifier: "",
      showLink: "true",
    },
    {
      stockistName: "Amazon",
      stockistLink: "https://www.amazon.co.uk/dp/B09HS9RK11",
      stockistImage: amazonLogo,
      classModifier: "",
      showLink: "true",
    },
    {
      stockistName: "Sainsbury's",
      stockistLink:
        "https://www.sainsburys.co.uk/gol-ui/product/blistex-lip-infusions-hydration-spf15-37g",
      stockistImage: sainsburysLogo,
      classModifier: "",
      showLink: "true",
    },
  ],
  whenToUseCopy: (
    <div>
      <p>
        Simply twist the stick and apply evenly to your lips. Re-apply as often
        as you like, especially if you're out and about in dry, cold or sunny
        weather.
      </p>
    </div>
  ),
  ingredients: (
    <p>
      hydrogenated coconut oil, phenyl trimethicone, euphorbia cerifera (candelilla) wax, cera alba, bis-diglyceryl polyacyladipate-2, C10-30 cholesterol/lanosterol esters, dimethicone, tocopheryl acetate, ethylhexyl palmitate, aroma, copernicia cerifera (carnauba) wax, arachidyl alcohol, arachidyl glucoside, behenyl alcohol, butylene glycol, citral, limonene, linoleic acid, linolenic acid, phenoxyethanol, saccharin, silica dimethylsilylate, sodium hyaluronate, tocopherol
    </p>
  ),
  faqs: [
    {
      question:
        "Does this product contain any ingredients derived from animals?",
      answer: "Yes, beeswax.",
    },
    {
      question: "Do you sell this product online?",
      answer:
        "Lip Infusions Hydration is available to buy online through key retailers. Please click on the where to buy link on each product page.",
    },
  ],
  relatedProducts: [
    {
      productName: "Lip Infusions - Nourish.",
      productLink: "/lip-infusions-nourish",
      productImage: lipInfusionsNourishProduct,
    },
    {
      productName: "Lip Infusions - Soothing.",
      productLink: "/lip-infusions-soothing",
      productImage: lipInfusionsSoothingProduct,
    },
  ],
}

const lipInfusionsHydration = () => (
  <Layout noHeaderInLayout={false} makeFooterSticky={false}>
    <SEO title="Lip Infusions - Hydration." />
    <SingleProduct data={product} inStock={true} outOfStockMsg={null} />
  </Layout>
)

export default lipInfusionsHydration
